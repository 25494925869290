import { recaptchaSiteKey } from '../config';
import { events, emitEvent } from '../utilities/custom-events';
import { post } from '../utilities/router';
import { syncDates, validateRecaptcha } from '../utilities/forms';

export default class {
    constructor({
        id,
        redirectUri,
        formValues,
    }) {
    // Elements and class variables
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const dates = form.querySelectorAll('[type="date"]');
        const submit = form.querySelector('[type="submit"]');
        const formElements = form.elements;
        // const debug = window.location.pathname === '/reynolds-insider-kiosks';
        const debug = false;

        let processing = false;

        // When the window loads, check to see if there is a query string and if so,
        // put the values of each key in an array. Place values in corresponding
        // fields in the long form.
        if (formValues) {
            Object.entries({
                date3: 'arrivaldate',
                date4: 'departuredate',
                number4: 'adultcount',
                number3: 'childcount',
                firstName: 'firstName',
                lastName: 'lastName',
                email: 'email',
                phone: 'phone',
                state: 'state',
                country: 'country',
                hearAboutUs20: 'hearAboutUs20',
            }).forEach(([key, value]) => {
                if (formElements[`fields-${key}`] && formValues[value]) {
                    formElements[`fields-${key}`].value = formValues[value];
                }
            });
        }

        // Event handler functions
        async function handleSubmit(e) {
            e.preventDefault();

            if (processing) {
                return;
            }

            processing = true;
            submit.setAttribute('disabled', true);

            const isValid = await validateRecaptcha(recaptchaSiteKey);

            if (debug) {
                if (!window.confirm(`reCAPTCHA validation status: ${isValid}. Continue?`)) {
                    processing = false;
                    submit.removeAttribute('disabled');
                    emitEvent(events.stopLoader);

                    return;
                }
            }

            if (!isValid) {
                processing = false;
                submit.removeAttribute('disabled');

                console.error('Invalid reCAPTCHA request'); // eslint-disable-line no-console

                return;
            }

            const formData = new FormData(form);

            // Handle post response
            function cb(res) {
                emitEvent(events.stopLoader);

                if (debug) {
                    if (!window.confirm(`Response received: ${res} Continue?`)) {
                        processing = false;
                        submit.removeAttribute('disabled');
                        emitEvent(events.stopLoader);

                        return;
                    }
                }

                const json = JSON.parse(res);

                if (json.code === 400) {
                    processing = false;

                    let errorCont = document.createElement('div');
                    errorCont.classList.add('error');
                    errorCont.innerText = 'Sorry, you have already unsubscribed from this mailing list.';

                    form.insertBefore(errorCont, submit);
                    return;
                }

                // Handle errors
                if (json.errors) {
                    processing = false;
                    submit.removeAttribute('disabled');
                    emitEvent(events.showFormErrors, { errors: json.errors });

                    return;
                }

                // Handle success - Disable current form and redirect
                if (json.success) {
                    form.removeEventListener('submit', handleSubmit);
                    form.remove();
                    window.location = redirectUri;
                }
            }

            emitEvent(events.startLoader);

            if (debug) {
                if (!window.confirm('Start back end form request?')) {
                    processing = false;
                    submit.removeAttribute('disabled');
                    emitEvent(events.stopLoader);

                    return;
                }
            }

            post({ formData, cb });
        }

        // Initialize
        form.addEventListener('submit', handleSubmit);

        if (dates.length === 2) {
            syncDates(...dates);
        }
    }
}
