export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const svg = el.querySelector('svg');
        const links = svg ? svg.querySelectorAll('a') : [];
        const properties = el.querySelectorAll('li');        

		console.log('el:', el);
		console.log('svg:', svg);
		console.log('links:', links);
		console.log('properties:', properties);
    }
}
