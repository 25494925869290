import Swiper from 'swiper';
import { events, emitEvent } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        overlayHandle,
        contHandle,
        prevHandle,
        nextHandle,
        expandHandle,
        activeClass,
        lazy = false,
		numSlides,
    }) {
    // Elements and class variables
        const el = document.getElementById(id);
        const overlay = el.querySelector(overlayHandle);
        const cont = el.querySelector(contHandle);
        const images = cont.querySelectorAll('img');
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);
        const expand = el.querySelector(expandHandle);

        let inst = null;
        let activeVideo = null;

        function handleClose() {
            el.classList.remove(activeClass);
            emitEvent(events.hideOverlay);
        }
        function handleExpand() {
            if (el.classList.toggle(activeClass)) {
                emitEvent(events.showOverlay);
            } else {
                emitEvent(events.hideOverlay);
            }

            // Update slider after expanding
            if (!inst) {
                return;
            }

            inst.update();
        }
        function handleslideChange() {
            if (activeVideo) {
                activeVideo.pause();
            }

            const video = inst.slides[inst.activeIndex].querySelector('video');

            if (video) {
                activeVideo = video;

                activeVideo.play();
            }
        }
        function handleClick() {
            if (activeVideo === null) {
                return;
            }

            if (activeVideo.paused) {
                activeVideo.play();
            } else {
                activeVideo.pause();
            }
        }

        overlay.addEventListener('click', handleClose);
        expand.addEventListener('click', handleExpand);

        // Initialize slider
        let opts = {
            loop: numSlides > 1 ? true : false,
            slidesPerView: 'auto',
            centeredSlides: true,
            preventClicks: false,
            preventClicksPropagation: false,
            navigation: {
                prevEl: prev,
                nextEl: next,
            },
        }

        if (lazy) {
            opts = {
                ...opts,
                preloadImages: false,
                lazy: true,
                watchSlidesVisibility: true,
            };
        }

        inst = new Swiper(cont, opts);

        inst.on('slideChange', handleslideChange);
        inst.on('click', handleClick);

        if (lazy) {
            inst.on('lazyImageReady', inst.update);
        }
    }
}
