import Swiper from 'swiper';
import YouTubePlayer from 'youtube-player';
import { events, emitEvent } from '../utilities/custom-events';
import { isMobile } from '../utilities/devices';

export default class {
    constructor({
        id,
        galleryTriggerHandle,
        progressHandle,
        videoContHandle,
        videoId,
    }) {
        const el = document.getElementById(id);
        const firstImage = el.querySelector('img');
        const [prev, next] = el.querySelectorAll('nav > button');
        const galleryTrigger = el.querySelector(galleryTriggerHandle);
        const counters = galleryTrigger.querySelectorAll('i');
        const videoCont = el.querySelector(videoContHandle);
        const progress = el.querySelector(progressHandle);
        const onMobile = isMobile();
        const swiper = new Swiper(`#${id} .swiper-container`, {
            loop: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            preventClicks: false,
            preventClicksPropagation: false,
            navigation: {
                prevEl: prev,
                nextEl: next,
            },
            preloadImages: false,
            lazy: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });
        const player = videoId ? YouTubePlayer(videoCont, {
            width: 'auto',
            height: 'auto',
            videoId,
            playerVars: {
                autoplay: 1,
                controls: 0,
                playsinline: 1,
                showinfo: 0,
                loop: 1,
                modestbranding: 1,
            },
        }) : null;

        // This is f*ck!ng stupid
        if (onMobile) {
            const jiggle = () => {
                const startingTranslate = swiper.getTranslate();
                swiper.wrapperEl.style.transition = 'transform 0.3s ease-in-out';
                setTimeout(() => { swiper.setTranslate(startingTranslate - 100); }, 1000);
                setTimeout(() => { swiper.setTranslate(startingTranslate); }, 1500);
                setTimeout(() => { swiper.setTranslate(startingTranslate - 20); }, 1800);
                setTimeout(() => { swiper.setTranslate(startingTranslate); }, 2100);
            };

            if (firstImage.naturalWidth) {
                jiggle();
            } else {
                firstImage.onload = jiggle;
            }
        }
        galleryTrigger.onclick = () => {
            const gallery = galleryTrigger.nextElementSibling;
            const markup = gallery.innerHTML;
            const cb = content => {
                const images = content.querySelectorAll('img');
                let maxWidth = window.innerWidth;

                images.forEach(img => {
                    img.onload = () => {
                        const maxHeight = Math.max(img.offsetHeight, img.naturalHeight);

                        if (maxHeight > window.innerHeight) {
                            const { naturalWidth, naturalHeight } = img;
                            const ratio = naturalWidth / naturalHeight;

                            maxWidth = Math.min(maxWidth, window.innerHeight * ratio);

                            images.forEach(({ style }) => {
                                style.maxWidth = `${maxWidth}px`;
                            });
                        }
                    };
                });
            };

            emitEvent(events.loadFancyModal, { markup, cb });
        };
        swiper.on('slideChange', () => {
            el.classList.toggle('hide-video', swiper.realIndex !== 0 || onMobile);
            counters.forEach(c => { c.innerText = swiper.realIndex + 1; });
            progress.style.transform = `scaleX(${(swiper.realIndex + 1) / swiper.loopedSlides})`;
        });
        if (player) {
            player.on('ready', () => {
                player.setVolume(0);
                player.playVideo();
                el.classList.add('show-video');
            });
            player.on('stateChange', event => {
                if (event.data === 0) { // Restart after videos ends
                    player.playVideo();
                }
            });
        }

        // Initialize progress scale
        progress.style.transform = `scaleX(${(swiper.realIndex + 1) / swiper.loopedSlides})`;
        el.classList.toggle('hide-video', onMobile);
    }
}
